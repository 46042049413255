import {
  ButtonDS,
  GlobalIcon,
  IconButton,
  InputDS,
} from "get-life-storybook-ts";
import numberFormat, {
  formatPriceWithoutCurrency,
} from "../../../utils/numberFormat";
import { useContext, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import { Accordion } from "./DashboardMultiproduct";
import { MultiproductContext } from "./MultiproductContext";
import DownloadableFilesModalDS from "../../components/DownloadableFiles/DownloadableFilesModalDS";
import apiLeadSendDocument from "../../../api/request/apiLeadSendDocument";
import { ContextGetlife } from "../../../contexts/ContextGetlife";

export type GuaranteeT = "basic" | "additional" | "accidental" | "promo";

const PricingSummary = () => {
  const [downloadableModal, setDownloadableModal] = useState<boolean>(false);
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { leadId, setShowNotification, token } = useContext(ContextGetlife);

  const {
    pricing,
    loading,
    navigateToQuestions,
    basicForm,
    downloadablePdfs,
    promoCode,
    setPromoCode,
    validateCoupon,
    promoLoading,
    fetchPromo,
    removePromo,
  } = useContext(MultiproductContext);

  const {
    DEATH,
    DISABILITY,
    TEMPORARY_DISABILITY,
    SERIOUS_ILLNESS,
    ACCIDENT_DISABILITY,
    TRAFFIC_ACCIDENT_DISABILITY,
    ACCIDENT_DEATH,
    TRAFFIC_ACCIDENT_DEATH,
  } = pricing?.capitals!;

  const MONTHLY_PRICE = pricing?.prices.monthly.promoted;
  const YEARLY_PRICE = pricing?.prices.yearly.promoted;

  const [open, setOpen] = useState<Record<GuaranteeT, boolean>>({
    basic: true,
    additional: true,
    accidental: true,
    promo: false,
  });

  const toggleOpen = (guarantee: GuaranteeT) => {
    setOpen((prevOpen) => ({ ...prevOpen, [guarantee]: !prevOpen[guarantee] }));
  };

  const sendEmailDocument = async (email: string, text: string) => {
    const type = "general-a-exclusions";
    const resource = null;
    const templates = ["offer-w-base"];
    try {
      await apiLeadSendDocument(
        token,
        leadId,
        type,
        text,
        email,
        resource,
        templates
      );
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.success"),
        type: "success",
        status: true,
      });
    } catch (e) {
      setDownloadableModal(false);
      setShowNotification({
        message: translate("downloadableFiles.modal.send.error"),
        type: "error",
        status: true,
      });
    }
  };

  const hasDisability = DISABILITY.active && DISABILITY.capital;
  const hasTotalDisability =
    TEMPORARY_DISABILITY.active && TEMPORARY_DISABILITY.capital;
  const hasSeriousIllness = SERIOUS_ILLNESS.active && SERIOUS_ILLNESS.capital;

  const hasComplementaryGuarantees =
    hasDisability || hasTotalDisability || hasSeriousIllness;

  const hasAccidentDeath = ACCIDENT_DEATH.active && ACCIDENT_DEATH.capital;
  const hasTrafficAccidentDeath =
    TRAFFIC_ACCIDENT_DEATH.active && TRAFFIC_ACCIDENT_DEATH.capital;
  const hasAccidentDisability =
    ACCIDENT_DISABILITY.active && ACCIDENT_DISABILITY.capital;
  const hasTrafficAccidentDisability =
    TRAFFIC_ACCIDENT_DISABILITY.active && TRAFFIC_ACCIDENT_DISABILITY.capital;

  const hasAdditionalAccidentGuarantees =
    hasAccidentDeath ||
    hasTrafficAccidentDeath ||
    hasAccidentDisability ||
    hasTrafficAccidentDisability;

  return (
    <>
      <div
        className="bg-white border border-[#9A98F1] rounded-lg p-[24px_20px_32px]"
        style={{
          boxShadow:
            "0px -1px 0px 0px rgba(66, 66, 66, 0.10), 0px 1px 3px 0px rgba(66, 66, 66, 0.10)",
        }}
      >
        <div className="flex flex-row justify-center gap-[16px] text-[var(--dark-primary-color)] BodyL font-bold pb-[12px] border-b border-b-[#D9D9D9]">
          <GlobalIcon iconName="FileTextIcon" color="currentColor" size="XS" />
          <span>{translate("dashboard.multiproduct.pricingSummary")}</span>
        </div>

        <div className="py-[12px] border-b border-b-[#D9D9D9]">
          <div className="flex flex-row justify-between gap-[8px] items-center text-[#424242] BodyM font-bold">
            <span>
              {translate("dashboard.multiproduct.mandatoryBasicGuarantee")}
            </span>
            <button
              className="p-[2px]"
              onClick={() => toggleOpen("basic")}
              style={{
                transform: open["basic"] ? "rotate(180deg)" : "rotate(0deg)",
              }}
            >
              <GlobalIcon iconName="ChevronDown" color="currentColor" />
            </button>
          </div>
          <Accordion open={open.basic} height={80}>
            <div className="pt-[12px] flex flex-row justify-between gap-[8px] items-center">
              <div className="flex flex-row gap-[8px]">
                <GlobalIcon
                  iconName="Cross2Icon"
                  size="XS"
                  color="var(--dark-primary-color)"
                />
                <span className="BodyM font-medium text-[#424242]">
                  {translate("dashboard.multiproduct.mandatoryDecease")}
                </span>
              </div>
              <span className="BodyL text-[#555555]">
                {numberFormat(DEATH.capital, "capital")}
              </span>
            </div>
          </Accordion>
        </div>

        {hasComplementaryGuarantees && (
          <div className="py-[12px] border-b border-b-[#D9D9D9]">
            <div className="flex flex-row justify-between gap-[8px] items-center text-[#424242] BodyM font-bold">
              <span>
                {translate("dashboard.multiproduct.additionalGuarantees")}
              </span>
              <button
                className="p-[2px]"
                onClick={() => toggleOpen("additional")}
                style={{
                  transform: open["additional"]
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              >
                <GlobalIcon iconName="ChevronDown" color="currentColor" />
              </button>
            </div>
            <Accordion open={open.additional} height={120}>
              <div className="flex flex-col gap-[10px] pt-[12px]">
                {hasDisability && (
                  <div className="flex flex-row justify-between gap-[8px] items-center">
                    <div className="flex flex-row gap-[8px]">
                      <GlobalIcon
                        iconName="DisabledIcon"
                        size="XS"
                        color="var(--dark-primary-color)"
                      />
                      <span className="BodyM font-medium text-[#424242]">
                        {translate("dashboard.multiproduct.ipa")}
                      </span>
                    </div>
                    <span className="BodyL text-[#555555]">
                      {numberFormat(DISABILITY.capital, "capital")}
                    </span>
                  </div>
                )}
                {hasTotalDisability && (
                  <div className="flex flex-row justify-between gap-[8px] items-center">
                    <div className="flex flex-row gap-[8px]">
                      <GlobalIcon
                        iconName="DisabledIcon"
                        size="XS"
                        color="var(--dark-primary-color)"
                      />
                      <span className="BodyM font-medium text-[#424242]">
                        {translate("dashboard.multiproduct.totalIpa")}
                      </span>
                    </div>
                    <span className="BodyL text-[#555555]">
                      {numberFormat(TEMPORARY_DISABILITY.capital, "capital")}
                    </span>
                  </div>
                )}
                {hasSeriousIllness && (
                  <div className="flex flex-row justify-between gap-[8px] items-center">
                    <div className="flex flex-row gap-[8px]">
                      <GlobalIcon
                        iconName="WiderUmbrellaIcon"
                        size="XS"
                        color="var(--dark-primary-color)"
                      />
                      <span className="BodyM font-medium text-[#424242]">
                        {translate("dashboard.multiproduct.seriousIllness")}
                      </span>
                    </div>
                    <span className="BodyL text-[#555555]">
                      {numberFormat(SERIOUS_ILLNESS.capital, "capital")}
                    </span>
                  </div>
                )}
              </div>
            </Accordion>
          </div>
        )}

        {hasAdditionalAccidentGuarantees && (
          <div className="py-[12px] border-b border-b-[#D9D9D9]">
            <div className="flex flex-row justify-between gap-[8px] items-center text-[#424242] BodyM font-bold">
              <span>
                {translate("dashboard.multiproduct.accidentalGuarantees")}
              </span>
              <button
                className="p-[2px]"
                onClick={() => toggleOpen("accidental")}
                style={{
                  transform: open["accidental"]
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
              >
                <GlobalIcon iconName="ChevronDown" color="currentColor" />
              </button>
            </div>
            <Accordion open={open.accidental} height={600}>
              {hasAccidentDeath || hasTrafficAccidentDeath ? (
                <div className="flex flex-col gap-[16px] mt-[12px] bg-[#f7f9fb] rounded-[10px] p-[12px_14px]">
                  <div className="flex flex-row items-center gap-[8px]">
                    <GlobalIcon
                      iconName="Cross2Icon"
                      size="XS"
                      color="var(--dark-primary-color)"
                    />
                    <span className="BodyM font-medium text-[#424242]">
                      {translate("dashboard.multiproduct.accidentalDecease")}
                    </span>
                  </div>
                  {hasAccidentDeath ? (
                    <div className="flex flex-col gap-[4px]">
                      <div className="pl-[16px] flex flex-row gap-[8px]">
                        <GlobalIcon
                          iconName="CrutchesIcon"
                          size="XS"
                          color="var(--dark-primary-color)"
                        />
                        <span className="mr-auto BodyM font-medium text-[#424242]">
                          {translate("dashboard.multiproduct.accident")}
                        </span>
                        <GlobalIcon
                          iconName="CircleCheckFilledIcon"
                          color="#018565"
                          size="XS"
                        />
                      </div>
                      <div className="pl-[64px] flex flex-row gap-[8px] text-[#555555]">
                        <span className="mr-auto BodyM font-medium">
                          {translate("dashboard.multiproduct.accidentCapital")}
                        </span>
                        <span className="BodyL">
                          {numberFormat(ACCIDENT_DEATH.capital, "capital")}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {hasTrafficAccidentDeath ? (
                    <div className="flex flex-col gap-[4px]">
                      <div className="pl-[16px] flex flex-row gap-[8px]">
                        <GlobalIcon
                          iconName="CarCrashIcon"
                          size="XS"
                          color="var(--dark-primary-color)"
                        />
                        <span className="mr-auto BodyM font-medium text-[#424242]">
                          {translate("dashboard.multiproduct.trafficAccident")}
                        </span>
                        <GlobalIcon
                          iconName="CircleCheckFilledIcon"
                          color="#018565"
                          size="XS"
                        />
                      </div>
                      <div className="pl-[64px] flex flex-row gap-[8px] text-[#555555]">
                        <span className="mr-auto BodyM font-medium">
                          {translate("dashboard.multiproduct.accidentCapital")}
                        </span>
                        <span className="BodyL">
                          {numberFormat(
                            TRAFFIC_ACCIDENT_DEATH.capital,
                            "capital"
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {hasAccidentDisability || hasTrafficAccidentDisability ? (
                <div className="flex flex-col gap-[16px] mt-[12px] bg-[#f7f9fb] rounded-[10px] p-[12px_14px]">
                  <div className="flex flex-row items-center gap-[8px]">
                    <GlobalIcon
                      iconName="Cross2Icon"
                      size="XS"
                      color="var(--dark-primary-color)"
                    />
                    <span className="BodyM font-medium text-[#424242]">
                      {translate("dashboard.multiproduct.accidentIpa")}
                    </span>
                  </div>
                  {hasAccidentDisability ? (
                    <div className="flex flex-col gap-[4px]">
                      <div className="pl-[16px] flex flex-row gap-[8px]">
                        <GlobalIcon
                          iconName="CrutchesIcon"
                          size="XS"
                          color="var(--dark-primary-color)"
                        />
                        <span className="mr-auto BodyM font-medium text-[#424242]">
                          {translate("dashboard.multiproduct.accident")}
                        </span>
                        <GlobalIcon
                          iconName="CircleCheckFilledIcon"
                          color="#018565"
                          size="XS"
                        />
                      </div>
                      <div className="pl-[64px] flex flex-row gap-[8px] text-[#555555]">
                        <span className="mr-auto BodyM font-medium">
                          Capital
                        </span>
                        <span className="BodyL">
                          {numberFormat(ACCIDENT_DISABILITY.capital, "capital")}
                        </span>
                      </div>
                    </div>
                  ) : null}
                  {hasTrafficAccidentDisability ? (
                    <div className="flex flex-col gap-[4px]">
                      <div className="pl-[16px] flex flex-row gap-[8px]">
                        <GlobalIcon
                          iconName="CarCrashIcon"
                          size="XS"
                          color="var(--dark-primary-color)"
                        />
                        <span className="mr-auto BodyM font-medium text-[#424242]">
                          {translate("dashboard.multiproduct.trafficAccident")}
                        </span>
                        <GlobalIcon
                          iconName="CircleCheckFilledIcon"
                          color="#018565"
                          size="XS"
                        />
                      </div>
                      <div className="pl-[64px] flex flex-row gap-[8px] text-[#555555]">
                        <span className="mr-auto BodyM font-medium">
                          Capital
                        </span>
                        <span className="BodyL">
                          {numberFormat(
                            TRAFFIC_ACCIDENT_DISABILITY.capital,
                            "capital"
                          )}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Accordion>
          </div>
        )}

        <div className="flex flex-col gap-[24px] mt-[24px] mb-[12px]">
          <div className="flex flex-row gap-[8px] items-center font-bold text-[#424242] BodyL">
            <span className="flex-1">
              {translate("dashboard.multiproduct.discountCoupon")}
            </span>
            <IconButton
              icon={open["promo"] ? "MinusIcon" : "PlusIcon"}
              buttonType="secondary"
              size="32"
              onClick={() => toggleOpen("promo")}
            />
          </div>
          <Accordion open={open["promo"]} height={250}>
            <div className={"flex flex-col items-end text-[#424242] pb-[24px]"}>
              <InputDS
                value={promoCode}
                name="promoCode"
                icon="Tag2Icon"
                placeholder={translate(
                  "dashboard.multiproduct.discountCouponPlacerHolder"
                )}
                onChange={(e) => setPromoCode(e.target.value)}
                disabled={validateCoupon === "validate" ? true : false}
                error={
                  validateCoupon === "invalidate"
                    ? translate("dashboard.calculator.promoInvalidate")
                    : undefined
                }
              />
              {validateCoupon === "validate" ? (
                <button
                  className={
                    "BodyS mr-auto text-[#8e8e8e] flex flex-row gap-[4px] items-center font-bold underline mt-[8px]"
                  }
                  onClick={() => removePromo(promoCode)}
                >
                  <GlobalIcon
                    iconName="TrashIcon"
                    size="XXS"
                    color="currentColor"
                  />
                  {translate("dashboard.calculator.removePromo")}
                </button>
              ) : null}
              <ButtonDS
                label={translate("dashboard.multiproduct.applyCoupon")}
                leftIcon="Tag2Icon"
                buttonType="tertiary"
                size="32"
                className="w-fit mt-[20px]"
                onClick={() => fetchPromo(promoCode)}
                disabled={promoLoading || validateCoupon === "validate"}
              />
            </div>
          </Accordion>
        </div>

        <div className="bg-[#ECF0FF] p-[20px_16px_24px] rounded-md flex flex-col gap-[8px]">
          <span className="BodyL text-[#555555] text-center block">
            {translate("dashboard.multiproduct.estimatedPrice")}
          </span>
          <div className="flex flex-col gap-[16px]">
            <div className="flex flex-col gap-[8px]">
              <span className="BodyS text-[#424242] font-medium">
                {translate("dashboard.multiproduct.monthlyFee")}
              </span>
              <div className="flex flex-col">
                <span
                  className="text-[#7b7b7b] font-medium BodyS"
                  dangerouslySetInnerHTML={{
                    __html: pricing?.promotion.beforePromoText || "",
                  }}
                />
                <span className="H3 text-[var(--primary-color)] font-medium">
                  {formatPriceWithoutCurrency(MONTHLY_PRICE)}
                  <span className="BodyS align-top">
                    {translate("dashboard.multiproduct.monthly")}
                  </span>
                </span>
                <span
                  className="text-[#7b7b7b] BodyS"
                  dangerouslySetInnerHTML={{
                    __html: pricing?.promotion.afterPromoText || "",
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-[8px]">
              <span className="BodyS text-[#424242] font-medium">
                {translate("dashboard.multiproduct.anualFee")}
              </span>
              <div className="flex flex-col">
                {pricing?.promotion.promotionalCode ? (
                  <span className="BodyS font-medium text-[#7B7B7B]">
                    {translate("dashboard.calculator.withPromo")}
                  </span>
                ) : null}
                <span className="H3 text-[var(--primary-color)] font-medium">
                  {formatPriceWithoutCurrency(YEARLY_PRICE)}
                  <span className="BodyS align-top">
                    {translate("dashboard.multiproduct.anual")}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[8px] mt-[28px]">
          <ButtonDS
            label={translate("dashboard.multiproduct.documents")}
            buttonType="secondary"
            className="w-full"
            leftIcon="FileDownload"
            onClick={() => setDownloadableModal(true)}
          />
          <ButtonDS
            label={translate("dashboard.multiproduct.continueRequest")}
            rightIcon="ArrowRightIcon"
            className="w-full"
            onClick={navigateToQuestions}
            disabled={loading}
          />
        </div>
      </div>

      <DownloadableFilesModalDS
        open={downloadableModal}
        setOpen={setDownloadableModal}
        downloadablePdfs={downloadablePdfs}
        emailToSend={basicForm.email}
        sendEmail={sendEmailDocument}
      />
    </>
  );
};

export default PricingSummary;
