import {
  ButtonDS,
  GlobalIcon,
  IconButton,
  InputDS,
} from "get-life-storybook-ts";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../contexts/i18n.context";
import { Accordion } from "./DashboardMultiproduct";
import { MultiproductContext } from "./MultiproductContext";
import ChangeCapitalModal from "./ChangeCapitalModal";

const BasicCalculator = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [editCapitalModal, setEditCapitalModal] = useState<boolean>(false);
  const [basicFormWasModified, setBasicFormWasModified] =
    useState<boolean>(false);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {
    basicForm,
    editBasicForm,
    basicBoundaries,
    leadId,
    postOrganic,
    basicFormError,
    toggleBasicFieldModified,
    pricing,
    handleKeyDown,
  } = useContext(MultiproductContext);
  const isCapitalDisabled = !basicBoundaries.available || !!leadId;

  useEffect(() => {
    setBasicFormWasModified(true);
  }, [basicForm]);

  return (
    <div className="newDashboard-tariffifier">
      <div className="flex flex-row items-center justify-between">
        <div className="BodyL font-bold flex flex-row items-center gap-[16px] text-[#424242]">
          <GlobalIcon
            iconName="SimpleUserIcon"
            size="XS"
            color="currentColor"
          />
          {translate("dashboard.multiproduct.personalInformation")}
        </div>
        <button
          className="p-[2px]"
          onClick={() => setOpen(!open)}
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        >
          <GlobalIcon iconName="ChevronDown" color="currentColor" />
        </button>
      </div>
      <Accordion height={800} open={open}>
        <>
          <div className="grid grid-cols-2 gap-x-[48px] gap-y-[16px] mt-[24px]">
            <InputDS
              label={translate("dashboard.multiproduct.birthDate.label")}
              type="date"
              helper={translate("dashboard.multiproduct.birthDate.helper")}
              icon="CalendarEventLineIcon"
              value={basicForm["birthDate"]}
              onChange={(e) => editBasicForm("birthDate", e.target.value)}
              onBlur={() => toggleBasicFieldModified("birthDate")}
              error={basicFormError["birthDate"]}
              onKeyDownCapture={handleKeyDown}
            />
            <InputDS
              name="referenceName"
              label={translate("dashboard.multiproduct.referenceName.label")}
              icon="UserSearchIcon"
              placeholder={translate(
                "dashboard.multiproduct.referenceName.placeholder"
              )}
              value={basicForm["referenceName"]}
              onChange={(e) => editBasicForm("referenceName", e.target.value)}
              error={basicFormError["referenceName"]}
              onBlur={() => toggleBasicFieldModified("referenceName")}
              onKeyDownCapture={handleKeyDown}
            />
            <InputDS
              name="phone"
              label={translate("dashboard.multiproduct.phone.label")}
              icon="DeviceMobile"
              placeholder={translate(
                "dashboard.multiproduct.phone.placeholder"
              )}
              value={basicForm["phone"]}
              onChange={(e) => editBasicForm("phone", e.target.value)}
              error={basicFormError["phone"]}
              onBlur={() => toggleBasicFieldModified("phone")}
              onKeyDownCapture={handleKeyDown}
            />
            <InputDS
              name="email"
              label={translate("dashboard.multiproduct.email.label")}
              icon="MailIcon"
              placeholder={translate(
                "dashboard.multiproduct.email.placeholder"
              )}
              value={basicForm["email"]}
              onChange={(e) => editBasicForm("email", e.target.value)}
              error={basicFormError["email"]}
              onBlur={() => toggleBasicFieldModified("email")}
              onKeyDownCapture={handleKeyDown}
            />
          </div>
          <div className="mt-[30px] flex flex-col gap-[14px]">
            <div className="flex flex-row items-center gap-[8px]">
              <span className="BodyM font-bold text-[#424242]">
                {translate("dashboard.multiproduct.deceaseGuarantee")}
              </span>{" "}
              <GlobalIcon
                iconName="CircleCheckFilledIcon"
                size="XS"
                color="#018565"
              />
              <span className="BodyM font-bold text-[--dark-primary-color]">
                {translate("dashboard.multiproduct.alwaysIncluded")}
              </span>
            </div>
            <div className="grid grid-cols-1 md:gap-x-[48px] gap-y-[16px]">
              <div className="flex flex-row items-center gap-[8px]">
                <div className="relative w-[calc(50%_-_24px)] min-w-fit">
                  <InputDS
                    type="number"
                    label={translate("dashboard.multiproduct.capital.label")}
                    placeholder={translate(
                      "dashboard.multiproduct.capital.placeholder"
                    )}
                    name="basicCapital"
                    icon="CurrencyEuroIcon"
                    onKeyDown={(e) => {
                      if (e.key === "," || e.key === ".") e.preventDefault();
                    }}
                    helper={
                      basicBoundaries.max && basicBoundaries.min
                        ? `${translate(
                            "dashboard.multiproduct.minimum"
                          )} ${new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 0,
                          }).format(basicBoundaries.min)}, ${translate(
                            "dashboard.multiproduct.maximum"
                          )} ${new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                            maximumFractionDigits: 0,
                          }).format(basicBoundaries.max)}`
                        : undefined
                    }
                    step={5000}
                    value={basicForm["capital"]}
                    onChange={(e) => {
                      const capital = parseInt(e.target.value);
                      if (isNaN(capital)) return;
                      editBasicForm("capital", capital);
                    }}
                    onBlur={() => {
                      let value = basicForm["capital"]!;
                      if (value > basicBoundaries.max!) {
                        value = basicBoundaries.max!;
                      }
                      if (value < basicBoundaries.min!) {
                        value = basicBoundaries.min!;
                      }
                      editBasicForm("capital", value);
                    }}
                    error={basicFormError["capital"]}
                    disabled={isCapitalDisabled}
                    onKeyDownCapture={handleKeyDown}
                  />
                  <div className="absolute right-[-58px] top-0 h-full flex pt-[34px]">
                    <IconButton
                      icon="EditIcon"
                      buttonType="tertiary"
                      disabled={!leadId}
                      onClick={() => setEditCapitalModal(true)}
                    />
                    <ChangeCapitalModal
                      open={editCapitalModal}
                      currentCapital={basicForm["capital"]}
                      onClose={() => setEditCapitalModal(false)}
                      onSave={() => setBasicFormWasModified(false)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        {pricing ? (
          basicFormWasModified ? (
            <ButtonDS
              label={translate("dashboard.multiproduct.recalculate")}
              className="w-fit ml-auto mt-[24px]"
              onClick={() => {
                setBasicFormWasModified(false);
                postOrganic();
              }}
              buttonType="secondary"
            />
          ) : null
        ) : (
          <ButtonDS
            label={translate("dashboard.multiproduct.calculate")}
            className="w-fit ml-auto mt-[24px]"
            onClick={() => {
              setBasicFormWasModified(false);
              postOrganic();
            }}
          />
        )}
      </Accordion>
    </div>
  );
};

export default BasicCalculator;
