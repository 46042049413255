import { createContext, useContext, useEffect, useState } from "react";
import { ContextGetlife } from "../../../contexts/ContextGetlife";
import HttpLeadRepository from "../../../api/request/Lead/lead.service";
import { SQLBoundariesResponse } from "../../../api/request/Lead/Model/Response/SQLBoundariesResponse";
import { ulid } from "ulid";
import OrganicSQLRequest from "../../../api/request/Lead/Model/Request/OrganicSQLRequest";
import { DateTime } from "luxon";
import { I18nContext } from "../../../contexts/i18n.context";
import REGEX from "../../../utils/regExp";
import { SQLPricingResponse } from "../../../api/request/Lead/Model/Response/SQLPricingResponse";
import { SQLRulesResponse } from "../../../api/request/Lead/Model/Response/SQLRulesResponse";
import OrganicSQLResponse from "../../../api/request/Lead/Model/Response/OrganicSQLResponse";
import { useNavigate } from "react-router";
import {
  BasicBoundariesI,
  basicBoundariesTemplate,
  BasicFieldsModifiedI,
  BasicFormErrorsI,
  BasicFormI,
  basicFormTemplate,
  EditBasicFormT,
  GuaranteeFormI,
  guaranteeFormTemplate,
  MultiproductProviderI,
  ToggleBasicFieldModifiedT,
  ToggleGuaranteeActiveT,
  UpdateGuaranteeCapitalT,
} from "./MultiproductData";
import HttpCouponsRepository from "../../../api/request/Coupons/Coupons.service";

interface MultiproductContextValuesI {
  basicForm: BasicFormI;
  editBasicForm: EditBasicFormT;
  basicBoundaries: BasicBoundariesI;
  leadId: string | undefined;
  postOrganic: () => void;
  basicFormError: BasicFormErrorsI;
  toggleBasicFieldModified: ToggleBasicFieldModifiedT;
  pricing: SQLPricingResponse | undefined;
  rules: SQLRulesResponse | undefined;
  guaranteeForm: GuaranteeFormI;
  toggleGuaranteeActive: ToggleGuaranteeActiveT;
  updateGuaranteeCapital: UpdateGuaranteeCapitalT;
  loading: boolean;
  updateDeathCapital: (capital: number) => void;
  navigateToQuestions: () => void;
  SQLLegalNote: string;
  downloadablePdfs: [{ template: string; url: string }] | [];
  resetForm: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  promoCode: string;
  setPromoCode: (code: string) => void;
  validateCoupon: string;
  promoLoading: boolean;
  fetchPromo: (code: string) => void;
  removePromo: (code: string) => void;
}

const MultiproductContext = createContext({} as MultiproductContextValuesI);

const MultiproductProvider = ({ children }: MultiproductProviderI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);
  const navigate = useNavigate();

  const SQLLegalNote =
    "https://storage.googleapis.com/getlife-bucket-1/public/pdf/note13.pdf";

  const { token, brokerId, leadId, setLeadId } = useContext(ContextGetlife);

  const leadRepository = new HttpLeadRepository(token);
  const couponsRepository = new HttpCouponsRepository(leadId, token);

  const [basicForm, setBasicForm] = useState<BasicFormI>({
    ...basicFormTemplate,
  });
  const [guaranteeForm, setGuaranteeForm] = useState<GuaranteeFormI>({
    ...guaranteeFormTemplate,
  } as GuaranteeFormI);
  const [basicFormError, setBasicFormError] = useState<BasicFormErrorsI>({});
  const [basicFieldsModified, setBasicFieldsModified] =
    useState<BasicFieldsModifiedI>({});
  const [basicBoundaries, setBasicBoundaries] = useState<BasicBoundariesI>({
    ...basicBoundariesTemplate,
  });
  const [pricing, setPricing] = useState<SQLPricingResponse | undefined>(
    undefined
  );
  const [rules, setRules] = useState<SQLRulesResponse | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadablePdfs, setDownloadablePdfs] = useState<
    [{ template: string; url: string }] | []
  >([]);
  const [promoCode, setPromoCode] = useState("");
  const [validateCoupon, setValidateCoupon] = useState("");
  const [promoLoading, setPromoLoading] = useState(false);

  const editBasicForm: EditBasicFormT = (field, value, callback) => {
    setBasicForm((prevBasicForm) => {
      const data = { ...prevBasicForm, [field]: value };

      if (callback) {
        callback(data);
      }

      return data;
    });
  };

  const validateBasicForm = (forceValidation = false) => {
    const _error: any = {};
    const { birthDate, capital, email, phone, referenceName } = basicForm;
    if (basicFieldsModified["birthDate"] || forceValidation) {
      if (birthDate) {
        const date = DateTime.fromISO(birthDate);
        const now = DateTime.now();
        const years = now.diff(date, "years").years;
        const isValid = years >= 18 && years <= 64;
        if (!isValid) {
          _error["birthDate"] = translate(
            "dashboard.multiproduct.validate.dateOutOfRange"
          );
        }
      } else {
        _error["birthDate"] = translate(
          "dashboard.multiproduct.validate.isMandatory"
        );
      }
    }
    if (basicFieldsModified["email"] && email) {
      const isValidEmail = email.match(REGEX["EMAIL"]);
      if (!isValidEmail) {
        _error["email"] = translate(
          "dashboard.multiproduct.validate.wrongEmail"
        );
      }
    }

    if (basicFieldsModified["phone"] && phone) {
      const isValidPhone = phone.match(REGEX["PHONE"]);
      if (!isValidPhone) {
        _error["phone"] = translate(
          "dashboard.multiproduct.validate.wrongPhone"
        );
      }
    }

    if (
      basicFieldsModified["referenceName"] ||
      basicFieldsModified["phone"] ||
      basicFieldsModified["email"] ||
      forceValidation
    ) {
      if (!referenceName && !phone && !email) {
        _error["referenceName"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
        _error["phone"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
        _error["email"] = translate(
          "dashboard.multiproduct.error.atLeastOneMandatory"
        );
      }
    }

    setBasicFormError(_error);
    return _error;
  };

  const toggleBasicFieldModified: ToggleBasicFieldModifiedT = (field) => {
    if (!basicFieldsModified[field]) {
      setBasicFieldsModified({ ...basicFieldsModified, [field]: true });
    }
  };

  const getBoundaries = async () => {
    leadRepository
      .getSQLBoundaries({ birthdate: basicForm["birthDate"] as string })
      .then((response: SQLBoundariesResponse) => {
        setBasicBoundaries(response);
        if (response.max && response.available) {
          if (!basicForm["capital"]) {
            editBasicForm("capital", response.max / 2);
          } else {
            const capitalIsOutOfRange =
              basicForm["capital"] < response.min ||
              basicForm["capital"] > response.max;
            if (capitalIsOutOfRange) {
              editBasicForm("capital", response.max / 2);
            }
          }
        }
      })
      .catch(() => {
        setBasicBoundaries({ min: 0, max: 0, available: false });
        editBasicForm("capital", undefined);
      });
  };

  const postOrganic = async (formData?: BasicFormI) => {
    const errors = validateBasicForm(true);
    const hasErrors = Object.keys(errors).length > 0;
    if (!hasErrors) {
      let _leadId = "";
      let method: "post" | "put" = "post";

      if (!leadId) {
        _leadId = ulid();
        method = "post";
        setLeadId(_leadId);
      } else {
        _leadId = leadId;
        method = "put";
      }
      const form = formData ?? basicForm;
      const data: OrganicSQLRequest = {
        leadId: _leadId,
        brokerId: brokerId,
        ...form,
      } as OrganicSQLRequest;

      setLoading(true);

      leadRepository
        .postReducedOrganicSQL(data, method)
        .then(async (response: OrganicSQLResponse) => {
          Promise.all([getPricing(_leadId), getRules(_leadId)]).then(() => {
            setLoading(false);
          });
        })
        .catch(() => {
          // If an error occurs when the lead has not been created, clear the lead id to keep the method as post instead of put
          if (method === "post") setLeadId("");
          setLoading(false);
        });
    }
  };

  const getPricing = async (_leadId: string) => {
    await leadRepository
      .getSQLPricing(_leadId)
      .then((response: SQLPricingResponse) => {
        setPricing(response);
        setGuaranteeForm(response.capitals as GuaranteeFormI);
        getDownloadables(_leadId);
      });
  };

  const getRules = async (_leadId: string) => {
    await leadRepository
      .getSQLRules(_leadId)
      .then((response: SQLRulesResponse) => {
        setRules(response);
      });
  };


  const getDownloadables = async (_leadId: string) => {
    try {
      const templates = await leadRepository.getAvailableDocuments(_leadId);
      setDownloadablePdfs(templates);
    } catch (error) { }
  };

  const toggleGuaranteeActive: ToggleGuaranteeActiveT = (
    key: keyof GuaranteeFormI
  ) => {
    setGuaranteeForm(() => {
      const isActive = guaranteeForm[key].active;
      const trait = rules?.find((e) => e.trait === key);
      const capital = isActive ? 0 : trait?.min || 0;
      const guarantee = {
        ...guaranteeForm,
        [key]: {
          active: !guaranteeForm[key].active,
          capital: capital,
        },
      };

      updateComplementaryGuarantees(key, capital);

      return guarantee;
    });
  };

  const updateGuaranteeCapital: UpdateGuaranteeCapitalT = (
    key: keyof GuaranteeFormI,
    capital: number,
    callback = false
  ) => {
    setGuaranteeForm((prevGuarantee) => {
      const guarantee = {
        ...prevGuarantee,
        [key]: { ...prevGuarantee[key], capital },
      };
      if (callback) {
        updateComplementaryGuarantees(key, capital);
      }
      return guarantee;
    });
  };

  const updateDeathCapital = async (capital: number) => {
    editBasicForm("capital", capital, async (data) => {
      setLoading(true);
      await postOrganic(data);
      setLoading(false);
    });
  };

  const navigateToQuestions = async () => {
    if (leadId) {
      setLoading(true);
      await leadRepository.questionsByLeadId(leadId);
      setLoading(false);
      navigate(`/questions`);
    }
  };

  const updateComplementaryGuarantees = async (
    trait: keyof GuaranteeFormI,
    capital: number
  ) => {
    if (leadId) {
      setLoading(true);
      await leadRepository.updateAdditionalBoundaries(leadId, {
        trait: trait,
        capital: capital,
      });
      await getPricing(leadId);
      setLoading(false);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      (event.target as HTMLInputElement).blur();
    }
  };

  const resetForm = () => {
    setBasicForm({ ...basicFormTemplate });
    setGuaranteeForm({ ...guaranteeFormTemplate } as GuaranteeFormI);
    setLeadId("");
    setBasicFormError({});
    setBasicFieldsModified({});
    setBasicBoundaries({ ...basicBoundariesTemplate });
    setPricing(undefined);
    setRules(undefined);
    setLoading(false);
    setDownloadablePdfs([]);

    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.value = "";
    });
  };

  const fetchPromo = async (code: string) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.applyCoupon({
        promotionalCode: code,
      });
      if (response.promotionalCodeHasApplied) {
        setValidateCoupon("validate");
        await getPricing(leadId);
        // @TO-DO update downloadables here
      }
      setPromoLoading(false);
    } catch (error) {
      setValidateCoupon("invalidate");
      setPromoLoading(false);
    }
  };

  const removePromo = async (code: string) => {
    try {
      setPromoLoading(true);
      const response = await couponsRepository.removeCoupon({
        promotionalCode: code,
      });
      if (response.removed) {
        setValidateCoupon("");
        await getPricing(leadId);
        // @TO-DO update downloadables here
        setPromoCode("");
      }
      setPromoLoading(false);
    } catch (error) {
      setPromoLoading(false);
    }
  };

  useEffect(() => {
    if (basicForm["birthDate"]) {
      getBoundaries();
    }
  }, [basicForm.birthDate]);

  useEffect(() => {
    validateBasicForm();
  }, [basicForm, basicFieldsModified]);

  useEffect(() => {
    setLeadId("");
  }, []);

  return (
    <MultiproductContext.Provider
      value={{
        basicForm,
        editBasicForm,
        basicBoundaries,
        leadId,
        postOrganic,
        basicFormError,
        toggleBasicFieldModified,
        pricing,
        rules,
        guaranteeForm,
        toggleGuaranteeActive,
        updateGuaranteeCapital,
        loading,
        updateDeathCapital,
        navigateToQuestions,
        SQLLegalNote,
        downloadablePdfs,
        resetForm,
        handleKeyDown,
        promoCode,
        setPromoCode,
        validateCoupon,
        promoLoading,
        fetchPromo,
        removePromo,
      }}
    >
      {children}
    </MultiproductContext.Provider>
  );
};

export { MultiproductProvider, MultiproductContext };
